import { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../assets/4.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", color: "black" }}>
      <Link to="/" sx={{ flexGrow: 1, my: 4 }}>
        <img src={Logo} alt="logo" />
      </Link>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink activeClassName="active" to={"/"}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={"/motocicletas"}>Motos</NavLink>
        </li>
        <li>
          <NavLink to={"/seguros"}>Seguros</NavLink>
        </li>
        <li>
          <NavLink to={"/requisitos4rent"}>Requisitos</NavLink>
        </li>
        <li>
          <NavLink to={"/condiciones4rent"}>Condiciones</NavLink>
        </li>
        <li>
          <NavLink to={"/rentar"}>Rentar</NavLink>
        </li>
      </ul>
    </Box>
  );

  return (
    <>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: "white", height: "70px" }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Link to="/" sx={{ flexGrow: 0 }}>
              <Typography component="div" sx={{ flexGrow: 1 }}>
                <img src={Logo} alt="logo" />
              </Typography>
            </Link>
            <IconButton
              color="Black"
              aria-label="open drawer"
              edge="end"
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", sm: "block", marginInline: "center", alignItems: "center" },
              }}
            >
              <ul className="navigation-menu">
                <li>
                  <NavLink activeClassName="active" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/motocicletas"}>Motos</NavLink>
                </li>
                <li>
                  <NavLink to={"/seguros"}>Seguros</NavLink>
                </li>
                <li>
                  <NavLink to={"/requisitos4rent"}>Requisitos</NavLink>
                </li>
                <li>
                  <NavLink to={"/condiciones4rent"}>Condiciones</NavLink>
                </li>
                <li>
                  <NavLink to={"/rentar"}>Rentar</NavLink>
                </li>
              </ul>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "220px",
                color: "black",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};

export default Header;

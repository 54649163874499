import { FaInstagram, FaLocationArrow, FaMap, FaMobileAlt, FaTiktok } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Footer.css'; 

const FooterLinks = [
  { title: "Home", link: "/#alquilerdemotos", id: "alquilerdemotos" },
  { title: "Nosotros", link: "/nosotros#nosotros", id: "nosotros" },
  { title: "Contacto", link: "/contacto#contacto", id: "contacto" },
  { title: "Blog", link: "/blog#blog", id: "blog" },
];

const FooterLinks3 = [
  { title: "Rentar", link: "/rentar#rentar", id: "rentar" },
  { title: "Condiciones 4Rent", link: "/condiciones4rent#condiciones", id: "condiciones" },
  { title: "Requisitos 4Rent", link: "/requisitos4rent#requisitos", id: "requisitos" },
  { title: "Asesor", link: "/asesores#asesores", id: "asesores" },
];

const FooterLinks2 = [
  { title: "Dominar 400 ABS", link: "/dominar400#dominar", id: "dominar" },
  { title: "Fz 250 ABS", link: "/fz150#fz", id: "fz" },
  { title: "Nmax 155 ABS", link: "/nmax#nmax", id: "nmax" },
  { title: "Xre 300", link: "/xre300#xre", id: "xre" },
  { title: "Xtz 150", link: "/xtz150#xtz", id: "xtz" },
  { title: "Xpulse 200", link: "/xpulse200#xpulse", id: "xpulse" },
];

const handleScrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const Footer = () => {
  return (
    <footer className="footer-container">
      <section className="footer-section">
        <div className="footer-grid">
          <div className="footer-column">
            <h2 className="footer-title">Rentar una moto</h2>
            <p className="footer-description">Descubre la libertad en cada aventura. ¡En 4rent, tus sueños toman el control!</p>
            <div className="footer-item">
              <FaLocationArrow />
              <p> Bogotá y Medellín</p>
            </div>
            <div className="footer-item ">
              
              <div className="footer-items-lineal">
              <p><FaMap /> Calle 86 # 87A - 04 Engativá, Bogotá D.C</p>
     
     <p><FaMap /> Carrera 48 #86 - 61, San Fernando, Itagüí. Antioquia</p>


              </div>
          
            </div>
            <div className="footer-item">
              <FaMobileAlt />
              <p> +57 315 4573482</p>
            </div>
            <div className="footer-item">
              <MdEmail />
              <a href="mailto:reservas@4rent.com.co"><p>reservas@4rent.com.co</p></a>

            </div>
       
          </div>
          <div className="footer-column">
            <h2 className="footer-title">Información</h2>
            <ul className="footer-links-list">
              {FooterLinks.map((link, index) => (
                <li key={index} className="footer-link-item">
                  <Link to={link.link} onClick={() => handleScrollToSection(link.id)} className="footer-link">{link.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-column">
            <h2 className="footer-title">Modelos</h2>
            <ul className="footer-links-list">
              {FooterLinks2.map((link, index) => (
                <li key={index} className="footer-link-item">
                  <Link to={link.link} onClick={() => handleScrollToSection(link.id)} className="footer-link">{link.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-column">
            <h2 className="footer-title">Servicios</h2>
            <ul className="footer-links-list">
              {FooterLinks3.map((link, index) => (
                <li key={index} className="footer-link-item">
                  <Link to={link.link} onClick={() => handleScrollToSection(link.id)} className="footer-link">{link.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section>
      <div className="footer-icons">
  <a 
    href="https://www.tiktok.com/@4rent.colombia?lang=es" 
    target="_blank" 
    rel="noopener noreferrer" 
    aria-label="Tiktok de 4rent"
    className="footer-icon-item"
  >
    <FaTiktok />
    <p>Tiktok</p>
  </a>
  <a 
    href="https://www.instagram.com/4rent.col/" 
    target="_blank" 
    rel="noopener noreferrer" 
    aria-label="Instagram de 4rent"
    className="footer-icon-item"
  >
    <FaInstagram />
    <p>Instagram</p>
  </a>
</div>

      </section>
    </footer>
  );
};

export default Footer;
